<template>
  <div>Hi {{email}}</div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: 'Home',
  computed: {
    ...mapState(['user']),
    email() {
      return this.user?.attributes?.email
    }
  }
}
</script>
